<template>
  <div class="bg">
    <div class="bg-image">
      <div class="row" style="padding-top: 150px">
        <div class="col-lg-6 mx-auto text-center">
          <h4 style="font-weight: bold; color: white" class="text-uppercase">
            SISTEM INFORMASI JARINGAN IRIGASI
          </h4>
          <!-- <p style="font-weight: bold; color: white">
            Sampaikan laporan Anda langsung kepada UPTD PJU Dinas PUTR Kabupaten
            Bandung
          </p> -->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 mx-auto">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="text-uppercase text-main text-center">
                <b class="text-dark"> Petunjuk pengaduan </b>
              </h5>
              <hr />
              <ul>
                <li
                  class="text-justify text-main text-dark"
                  v-for="item in rules"
                  :key="item"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 mx-auto">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="text-uppercase text-main text-center">
                <b class="text-dark"> Sampaikan laporan anda </b>
              </h5>
              <hr />
              <p class="text-dark">Nama :</p>
              <input class="form-control" v-model="pengaduan.sumber_dana" />
              <br />
              <p class="text-dark">No. Telepon :</p>
              <input class="form-control" v-model="pengaduan.pemenang_tender" />
              <br />
              <p class="text-dark">Alamat Pengadu :</p>
              <input class="form-control" v-model="pengaduan.alamat_pekerjaan" />
              <br />
              
              <p class="text-dark">Isi laporan :</p>
              <textarea
                class="form-control"
                name=""
                v-model="pengaduan.nama_paket"
                placeholder="Contoh : Saluran air tersumbat di sekitar ..... - Banjaran, Soreang, Jawa Barat"
                id=""
                cols="30"
                rows="10"
              ></textarea>

              <br />
              <p class="text-dark">Lokasi :</p>

              <div style="height: 300px; width: 100%">
                <l-map ref="map" :zoom="zoom" :center="center" @click="pinMap">
                  <l-tile-layer :url="url" :attribution="attribution" />
                  <l-geosearch :options="geoSearchOptions"></l-geosearch>
                  <l-marker
                    v-if="marker"
                    visible
                    draggable
                    :lat-lng.sync="marker"
                  >
                    <!-- <l-tooltip
                    :content="tooltipContent"
                    :options="{ permanent: true }"
                  /> -->
                  </l-marker>
                </l-map>
              </div>
              <br />
              <p class="text-dark">Longtitude :</p>
              <input class="form-control" v-model="pengaduan.longitude" />
              <p class="text-dark">Latitude :</p>
              <input class="form-control" v-model="pengaduan.latitude" />
              <br />
              <p class="text-dark">Kecamatan :</p>
              <select class="form-control" v-model="pengaduan.kecamatan">
                <option
                  v-for="kecamatan in kecamatans"
                  :value="kecamatan.name"
                  :key="kecamatan.name"
                >
                  {{ kecamatan.name }}
                </option>
              </select>
              <br />
              <p class="text-dark">Alamat :</p>
              <textarea
                class="form-control"
                name=""
                id=""
                cols="30"
                rows="5"
                placeholder="Masukkan detail alamat"
                v-model="address"
              ></textarea>
              <br />
              <p class="text-dark">Dokumen :</p>
              <div class="row">
                <div class="col-lg-3" @click="getDocs">
                  <div
                    style="
                      height: 150px;
                      border-color: #036163 !important ;
                      border-style: dashed !important;
                    "
                    class="
                      border
                      text-center
                      d-flex
                      flex-column
                      justify-content-center
                    "
                  >
                    <span
                      class="material-icons"
                      style="font-size: 40px; color: #036163"
                      >add_photo_alternate</span
                    >
                    <p class="text-main mt-1 text-dark">
                      Upload Foto <br />
                      <span style="font-size: 9px">(maks: 2MB) </span>
                    </p>
                    <input
                      type="file"
                      accept=".png,.jpg,.pdf"
                      @change="setDocs"
                      id="docs"
                      hidden
                    />
                  </div>
                  <div class="progress mt-3">
                    <div
                      class="
                        progress-bar progress-bar-striped progress-bar-animated
                      "
                      role="progressbar"
                      :style="
                        'width: ' +
                        this.progress +
                        '%; background-color: #0d6769;'
                      "
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <p class="m-0 text-center" style="font-weight: bold">
                        {{ this.progress.toFixed(0) + " %" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="margin-bottom: 33px"
                  class="
                    col-lg-3
                    d-flex
                    text-center
                    flex-column
                    justify-content-center
                  "
                  v-for="item in preview"
                  :key="item"
                >
                  <vue-load-image>
                    <img
                      :src="item"
                      style="
                        height: 150px;
                        width: 100%;
                        object-fit: cover !important;
                      "
                      slot="image"
                      alt=""
                    />
                    <img
                      slot="preloader"
                      height="50px"
                      width="50px"
                      src=""
                    />
                    <div slot="error">error message</div>
                  </vue-load-image>
                </div>
              </div>
              <hr />
              <div class="row mt-3 d-flex flex-row justify-content-end">
                <div class="col-lg-12 mx-lg-auto">
                  <button class="btn btn-primary bg-main btn-confirm" @click="postData">
                    <b> Buat Laporan ! </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import { Icon, latLng } from "leaflet";
import 'leaflet/dist/leaflet.css';
import 'material-icons/iconfont/material-icons.css';
import "leaflet-geosearch/dist/geosearch.css";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import LGeosearch from "vue2-leaflet-geosearch";
import { uploadImage } from "@/utils/fileUpload"

import axios from "axios";
import firebase from "firebase/app";
import VueLoadImage from "vue-load-image";
// import AuthModal from "@/components/Shared/AuthModal.vue";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LGeosearch,
    "vue-load-image": VueLoadImage,
    // AuthModal,
  },
  data() {
    return {
      map: null,
      geoSearchOptions: {
        provider: new OpenStreetMapProvider(),
        showMarker: false,
        autoClose: true,
      },
      address: null,
      zoom: 15,
      center: latLng(-7.025253, 107.51976),
      marker: latLng(-7.025253, 107.51976),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      rules: [
        "Anda diwajibkan mengisi kolom nama lengkap, nomor telepon, dan laporan pada formulir pengaduan.",
        "Pelapor mengisi laporan lokasi jaringan irigasi secara lengkap dan jelas guna mempercepat proses perbaikan.",
        "Pelapor mengisi formulir pengaduan dengan lengkap dan benar, data nama lengkap dan nomor telepon pelapor yang ditampilkan akan disamarkan.",
      ],
      // •	Anda diwajibkan mengisi kolom nama lengkap, nomor telepon, dan laporan pada formulir pengaduan.
      // •	Pelapor mengisi laporan lokasi lampu PJU yang mati secara lengkap dan jelas guna mempercepat proses perbaikan.
      // •	Pelapor mengisi formulir pengaduan dengan lengkap dan benar, data nama lengkap dan nomor telepon pelapor yang ditampilkan akan disamarkan.
      // •	Foto/file dapat dilampirkan sebagai file pendukung.
      // •	Perbaikan lampu PJU akan dilaksanakan secepat mungkin setelah laporan kami terima.

      progress: 0,
      preview: [],
      pengaduan: {
        content: "",
        address: "",
        longitude: "",
        latitude: "",
        created_by: null,
        status: 0,
        paket_pekerjaan_befores: [],
      },
      kecamatans: [],
    };
  },
  methods: {
    getKecamatan() {
      this.$store
        .dispatch("sbu/getKecamatan", this.params)
        .then((resp) => {
          this.kecamatans = resp.data
          console.log(this.kecamatans, "kecamatan")
        })
        .catch((e) => {
          this.$toast.error("gagal mengambil data  \n", e)
        })
    },
    postData() {
      if (
        confirm("Pastikan semua data benar dan tidak ada kolom yang kosong !!")
      ) {
        // this.$modal.show("check-token");
        // const user = JSON.parse(localStorage.getItem("user"));
        // this.pengaduan.created_by = user.id;
        this.pengaduan.address = this.address;
        this.pengaduan.latitude = this.marker.lat;
        this.pengaduan.longitude = this.marker.lng;
        this.preview.forEach((element) => {
          this.pengaduan.paket_pekerjaan_befores.push({
            url_file: element,
            type_user: 0,
            type: "image",
            image: element,
          });
        });
        setTimeout(() => {
          this.$store
            .dispatch("paket_pekerjaan_public/addPaketPekerjaan", this.pengaduan)
            .then((resp) => {
              this.$toast.success("Pengaduan sudah diterima!!");
              this.$router.push("/pengaduan/home");
              // this.$router.replace({
              //   name: "detail",
              //   params: { data: this.pengaduan },
              // });
              // const id = resp.no_complaint;
              // this.$store
              //   .dispatch("complaint/getComplaintById", { _id: id })
              //   .then((resp) => {
              //     this.$router.replace({
              //       name: "detail",
              //       params: { data: resp },
              //     });
              //     this.$modal.hide("check-token");
              //   });
            })
            .catch((err) => {
              console.log(err);
              this.$toast.error("Permintaan Gagal Diproses!! |" + err);
              // this.$modal.hide("check-token");
            });
        }, 2000);
      }
    },
    getDocs() {
      this.preview.length < 2
        ? document.getElementById("docs").click()
        : alert("Maksimal 2 Foto !!");
    },
    setDocs(event) {
      this.uploadFile(event.target.files[0]);
    },
    uploadFile(file) {
      var loading = this.$loading.show()
      uploadImage(file)
        .then((resp) => {
          this.preview.push(resp)
          loading.hide()
          alert("File berhasil diupload !!")
        })
        .catch((e) => {
          loading.hide()
          alert("Terjadi kesalahan !! | " + e)
        })

      // var storageRef = firebase
      //   .storage()
      //   .ref("docPengaduan/" + file.name)
      //   .put(file);
      // // storageRef.put(this.file);
      // storageRef.on(
      //   "state_changed",
      //   (snapshot) => {
      //     console.log(snapshot);
      //     this.progress =
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //   },
      //   (error) => {
      //     console.log(error.message);
      //     alert("Permintaan Gagal Diproses!!\n\n" + error.message);
      //   },
      //   () => {
      //     this.progress = 100;
      //     storageRef.snapshot.ref.getDownloadURL().then((resp) => {
      //       this.preview.push(resp);
      //       // console.log();
      //       alert("Selamat! File Berhasil Diupload!!");
      //       this.progress = 0;
      //     });
      //   }
      // );
    },
    pinMap(value) {
      this.marker = latLng(value.latlng.lat, value.latlng.lng);
      this.getAddress();
    },
    getAddress() {
      // https://maps.googleapis.com/maps/api/geocode/json?&latlng=-6.903375,107.607935&key=AIzaSyCile1Arb1ipCD0WM8cDkmYL-dhgR6XBK8
      const lat = this.marker.lat;
      const lng = this.marker.lng;
      if (this.marker.lat && this.marker.lng) {
        this.pengaduan.latitude = this.marker.lat;
        this.pengaduan.longitude = this.marker.lng;
      }
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`
        )
        .then((resp) => {
          console.log(resp);
          this.address = resp.data.display_name;
        });
    },
  },

  mounted() {
    // this.$modal.show("check-token");
    this.getKecamatan()
  },
};
</script>

<style scoped>
.bg {
  background: rgb(103, 160, 161);
  background: linear-gradient(
    270deg,
    rgba(103, 160, 161, 1) 0%,
    rgba(3, 97, 99, 1) 100%
  );
}
.btn-confirm {
  width: 100%;
  border-radius: 20px;
}

.card {
  border-radius: 20px;
}
.bg-image {
  /* background-image: url("../assets/bg.png"); */
  background-size: cover;
}
</style>
